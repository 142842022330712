export function handleVideoClick(targetElement: EventTarget) {
  // WARNING:
  // Before changing this implementation, please review all referencing code.

  if (!targetElement) {
    return;
  }

  const videoWrapper = targetElement.closest(".iframeMe");
  if (!videoWrapper) {
    return;
  }

  // Note: When fetching data attributes, you need to remember the mapping of:
  //       kebab case ("moshe-ufnik") --> camel case ("mosheUfnik")
  const videoUrl = videoWrapper.dataset["videoUrl"];
  const videoTitle = videoWrapper.dataset["videoTitle"];
  videoWrapper.outerHTML = `
        <iframe
              style="background-color:black"
              title=${videoTitle}
              loading="lazy"
              class="player"
              data-title=${videoTitle}
              src=${videoUrl}
              frameborder="0"
              height="350"
              width="100%"
              allowfullscreen />"
        `;
}
