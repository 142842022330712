<script lang="ts" context="module">
  import Checkbox from "@ui/Checkbox.svelte";
</script>

<script lang="ts">
  export let id: string;
  export let name: string | undefined = undefined;
  export let text: string;
  export let checked: boolean = false;
  export let disabled: boolean = false;
  export let hoverover: boolean = false;
  export let highlight: boolean = false;
  export let value: any = undefined;
  export let group: any = undefined;
</script>

<label
  for={id}
  class="flex items-center w-full rounded-sm ring-purple-400 ring-offset-2"
  class:ring={highlight}
  on:mouseenter={() => (hoverover = true)}
  on:mouseleave={() => (hoverover = false)}>
  <Checkbox {id} bind:checked {disabled} {name} {value} on:change />
  <slot />
  <span class="flex-shrink-0 ml-1">{text}</span>
</label>
