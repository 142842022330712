import { getContext, setContext } from "svelte";
const context_key = {};

interface ShowContextProps {
  showPromotionBar: boolean;
  showFullBar?: boolean;
  showMinimalBar?: boolean;
  showSocialIcons: boolean;
  showAddReaction: boolean;
  showTranslations: boolean;
  showAccessibilityIcons: boolean;
  translateView: boolean;
  smoreImageUrl: string;
  itsAwesomeImageUrl: string;
  showTOC: boolean;
  showViewCount: boolean;
  viewCount: number;
  hideToolbar: boolean;
  hasPolls?: boolean;
  newsletterId: string;
}

export function getShowContext(): ShowContextProps {
  return getContext(context_key) as ShowContextProps;
}

export function createShowContext(context: ShowContextProps): ShowContextProps {
  setContext(context_key, context);
  return context;
}
