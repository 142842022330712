<script lang="ts">
  import type { schema } from "@editor/schema";
  import DynamicBlockReplacer from "./DynamicBlockReplacer.svelte";
  import type { StaticBlockWithDynamicBlockReplacer } from "./";
  import { getJson } from "@ui/http";
  import { blocksConfig } from "@editor/app/blocks/blocksConfig";

  export let newsletter: schema.Newsletter;
  export let hasPolls: boolean = false;
  export let allowTableOfContent: boolean;

  async function handlePolls(replacer: StaticBlockWithDynamicBlockReplacer) {
    try {
      const polls = await getJson<(schema.PollBlock & { myAnswerIds: string[] })[]>(`/app/polls/get_all/${newsletter.id}`);

      polls.forEach((poll) => {
        // await new Promise((resolve) => setTimeout(resolve, 20000));
        replacer(newsletter, poll, blocksConfig.poll.render);
      });
    } catch (e) {
      console.error(e);
    }
  }
</script>

{#if hasPolls}
  <DynamicBlockReplacer {newsletter} blockHandler={handlePolls} {allowTableOfContent} />
{/if}
