<script lang="ts">
  import { clickOutside } from "@ui/clickOutside";

  export let position: "top" | "bottom" = "bottom";
  export let isOpen: boolean = false;
  export let popupClass: string | undefined = undefined;
  import { fade } from "svelte/transition";
  let wrapper: HTMLDivElement;

  function close() {
    isOpen = false;
  }

  function toggleOpen(e: MouseEvent) {
    if (e.target === wrapper.firstChild) {
      isOpen = !isOpen;
    }
  }
</script>

{#if isOpen}
  <div class="fixed inset-0" style="z-index: 8888;" on:click|stopPropagation />
{/if}
<div use:clickOutside={close} class="relative notranslate" style="z-index: 8899;" on:click={toggleOpen} bind:this={wrapper}>
  <slot />
  {#if isOpen}
    <div
      class="absolute bg-white rounded-md shadow-lg b ring-4 ring-black ring-opacity-50 popup-container position-center"
      class:bottom-14={position === "top"}
      class:top-14={position === "bottom"}
      class:hidden={!isOpen}
      in:fade|local={{ duration: 150 }}
      out:fade|local={{ duration: 150 }}>
      <div class="p-3 pb-4 bg-white rounded-md">
        <div class="flex items-center justify-start">
          <slot name="icon" />
          <div class="ml-1">
            <slot name="title" />
          </div>
        </div>
        <div class="mt-2">
          <slot name="content" />
        </div>
        <div
          class:-top-3.5={position === "bottom"}
          class:-bottom-3.5={position === "top"}
          class:rotate-180={position === "top"}
          class:scale-x-150={position === "top" || "bottom"}
          class="absolute text-white transform select-none text-15 chevron-position material-icons"
          style="text-shadow: 0px -4.9px 0px rgb(0 0 0 / 50%);">
          &#9650;
        </div>
      </div>
    </div>
  {/if}
</div>

<style>
  .popup-container {
    border-radius: var(--radius, 0.375rem);
    width: var(--popup-width, 345px);
  }
  .chevron-position {
    left: var(--chevron-left, 50%);
  }
  .position-center {
    @apply -right-1/2;
    right: var(--right, inherit);
    top: var(--top, inherit);
  }
</style>
