import { getContext, setContext } from "svelte";
const context_key = {};

interface SocialButtonsProps {
  newsletterId: string,
  shortUrl: string,
  canonicalUrl: string,
  screenshotUrl: string,
  metaTitle: string,
  smoreBaseDomain: string,
}

export function getSocialButtonContext(): SocialButtonsProps {
  return getContext(context_key) as SocialButtonsProps;
}

export function createSocialButtonContext(context: SocialButtonsProps): SocialButtonsProps {
  setContext(context_key, context);
  return context;
}
