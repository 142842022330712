<script lang="ts">
  import { classicNewsletterApi } from "@editor/api";
  import { createNewsletterStores } from "@editor/app/newsletterStores";
  import { fromRawServer } from "@editor/schema/migrations";
  import type { schema } from "@editor/schema";
  import { SvelteComponent, getAllContexts, onMount } from "svelte";
  import type { StaticBlockWithDynamicBlockReplacer } from "./";

  export let newsletter: schema.Newsletter;
  export let allowTableOfContent: boolean;
  export let blockHandler: (replacer: StaticBlockWithDynamicBlockReplacer) => void;

  // Get the global context of the page so we can pass it to dynamically created components (e.g. Poll block)
  let thisGlobalPageContext = getAllContexts();
  const currentUserId = "";

  // Create the newsletter stores so "dynamic" blocks can use it when they load.
  createNewsletterStores(
    classicNewsletterApi(fromRawServer(newsletter) as schema.Newsletter, currentUserId),
    { userId: currentUserId, mode: "render-web" },
    { id: newsletter.id, short: newsletter.short, allowTableOfContent }
  );

  function replaceStaticBlockWithDynamic(newsletter: schema.Newsletter, block: schema.Block, blockComponentType: typeof SvelteComponent) {
    const blockId = block._id;

    // Mark block as "dynamic"
    block.isDynamic = true;

    const staticBlockWrapperElement = document.querySelector(`#block-${blockId} .block-wrapper`);
    if (!staticBlockWrapperElement) {
      return;
    }

    const staticBlockBoxElement = staticBlockWrapperElement.getElementsByClassName("block-box")[0];
    const before = staticBlockBoxElement.style.getPropertyValue("--before");
    const after = staticBlockBoxElement.style.getPropertyValue("--after");

    new blockComponentType({
      target: staticBlockWrapperElement.parentElement!,
      anchor: staticBlockWrapperElement,
      props: { blockComponent: blockComponentType, newsletter, block },
      context: thisGlobalPageContext
    });
    staticBlockWrapperElement.remove();

    const dynamicPollElement = document.querySelector(`#block-${blockId} .block-box`);
    dynamicPollElement!.style.setProperty("--before", before);
    dynamicPollElement!.style.setProperty("--after", after);
  }

  onMount(async () => {
    await blockHandler(replaceStaticBlockWithDynamic);
  });
</script>
